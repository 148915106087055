import '../css/style.scss';
import Splide from '@splidejs/splide';
import simpleParallax from 'simple-parallax-js';
import '@splidejs/splide/css';


//.visual__descがブラウザの上部に来たら#fixedBannerに.activeを追加
document.addEventListener('DOMContentLoaded', function () {
    const fixedBanner = document.getElementById('fixedBanner');
    const visualDesc = document.querySelector('.visual__desc');

    if (fixedBanner) {
        if (visualDesc) {
            // .visual__desc がある場合、opacity: 0;
            fixedBanner.style.opacity = '0';

            // スクロールによって .visual__desc の位置を監視し、クラスを追加/削除
            window.addEventListener('scroll', function () {
                const rect = visualDesc.getBoundingClientRect();

                // visual__desc が画面の上端を越えていったかをチェック
                if (rect.top + rect.height < 0) {
                    fixedBanner.classList.add('active');
                    fixedBanner.style.opacity = '1'; // 画面から消えたら表示
                } else {
                    fixedBanner.classList.remove('active');
                    fixedBanner.style.opacity = '0'; // 画面内にあれば隠す
                }
            });
        } else {
            // .visual__desc がない場合、opacity: 1;
            fixedBanner.style.opacity = '1';
        }
    }
});



document.addEventListener('DOMContentLoaded', () => {
    const imgFunction = function (src) {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = src;
            image.onload = () => resolve(image);
            image.onerror = (error) => reject(error);
        });
    };

    const imgs = document.getElementsByTagName('img');

    for (const img of imgs) {
        const src = img.getAttribute('src');

        imgFunction(src)
            .then((res) => {
                if (!img.hasAttribute('width')) {
                    img.setAttribute('width', res.width);
                }
                if (!img.hasAttribute('height')) {
                    img.setAttribute('height', res.height);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }
});


//ハンバーガーメニュー
document.addEventListener('DOMContentLoaded', function () {
    const hamburger = document.querySelector('[data-hamburger]');
    const menu = document.querySelector('[data-menu]');
    const globalNavList = document.querySelector('.globalNav__list');
    const navLinks = document.querySelectorAll('.globalNav__list a'); // アンカーリンクの取得

    if (hamburger && menu && globalNavList) {
        hamburger.addEventListener('click', function () {
            if (hamburger.getAttribute('data-hamburger') === 'false') {
                hamburger.setAttribute('data-hamburger', 'true');
                setTimeout(() => {
                    menu.setAttribute('data-menu', 'true');
                }, 350);
                setTimeout(() => {
                    globalNavList.classList.add('active');
                }, 500);
            } else {
                closeMenu(); // 共通のメニューを閉じる処理
            }
        });

        // 各アンカーリンクにクリックイベントを追加
        navLinks.forEach(function (link) {
            link.addEventListener('click', function () {
                closeMenu(); // アンカーリンクがクリックされたらメニューを閉じる
            });
        });
    }

    // メニューを閉じる処理を関数化
    function closeMenu() {
        globalNavList.classList.remove('active');
        setTimeout(() => {
            menu.setAttribute('data-menu', 'false');
        }, 300);
        setTimeout(() => {
            hamburger.setAttribute('data-hamburger', 'false');
        }, 300);
    }
});



const fadeTargets = document.querySelectorAll(".fadeIn");

const fadeOption = {
    root: null,
    rootMargin: "-20% 0px",
    threshold: [0]
};

const targets = (entries, observer) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.classList.add("active");
            observer.unobserve(entry.target);
        }
    }
    )
}

const fadeObserver = new IntersectionObserver(targets, fadeOption);

fadeTargets.forEach((target) => {
    fadeObserver.observe(target);
});


document.addEventListener('DOMContentLoaded', () => {
    // 監視対象の要素を取得
    const targets = document.querySelectorAll('[data-heading="grad-line"], [data-heading="grad-bg"], [data-heading="flex"]');

    // Intersection Observerの設定
    const observerOptions = {
        root: null, // ビューポートを基準にする
        threshold: 0.3 // 要素が20%可視になった時点でコールバックを実行
    };

    // Intersection Observerのコールバック関数
    const observerCallback = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                entry.target.classList.add('is-active');
            } else {
                entry.target.classList.remove('is-active');
            }
        });
    };

    // Intersection Observerのインスタンスを作成して監視対象を登録
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    targets.forEach(target => observer.observe(target));
});



//パララックス（TOP）
const parallax = document.querySelectorAll('.visual img');
new simpleParallax(parallax);





function triggerHair() {
    const heading = document.querySelector('.homeAboutContents__text .heading');

    // headingが存在しなければここで処理を終了
    if (!heading) {
        console.log('指定した要素がDOMに存在しません。');
        return; // 早期リターンで関数の実行を止める
    }

    //髪をイメージしたアニメーションの設定
    const options = {
        root: null,
        rootMargin: '-30%',
    };

    const callback = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const targets = document.querySelectorAll('[data-name="hairImage"]');
                let delayTime = 0;

                targets.forEach(target => {
                    setTimeout(() => {
                        target.style.transform = 'translateY(100%)';
                        target.style.opacity = '0';
                    }, delayTime);
                    delayTime += 200;
                });
            }
        });
    };

    const observer = new IntersectionObserver(callback, options);

    // ここでのobserveメソッドの呼び出しは、headingが実際に存在することが保証されている
    observer.observe(heading);
}

// DOMが完全にロードされた後に関数を実行
document.addEventListener('DOMContentLoaded', () => {
    triggerHair();
});



// アコーディオン
function toggleAccordion() {
    const accordions = document.querySelectorAll('[data-contents="accordion"]');

    if (accordions.length > 0) {
        accordions.forEach((accordion) => {
            accordion.addEventListener("click", function () {
                console.log("Accordion clicked:", this);
                this.classList.toggle("active");
                const panel = this.nextElementSibling;
                if (panel) {
                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                    } else {
                        panel.style.maxHeight = `${panel.scrollHeight}px`;
                    }
                } else {
                    console.log("Panel not found");
                }
            });
        });
    } else {
        console.log("No accordions found");
    }
}

toggleAccordion();

// 店舗一覧アコーディオン
const shopToggleAccordion = () => {
    document.querySelectorAll('.accordion-header').forEach(button => {
        button.addEventListener('click', () => {
            const accordionBody = button.nextElementSibling;

            if (accordionBody.style.maxHeight) {
                accordionBody.style.maxHeight = null;
                button.classList.remove('active');
            } else {
                document.querySelectorAll('.accordion-body').forEach(body => {
                    body.style.maxHeight = null;
                    body.previousElementSibling.classList.remove('active');
                });
                accordionBody.style.maxHeight = accordionBody.scrollHeight + 'px';
                button.classList.add('active');
            }
        });
    });
};

shopToggleAccordion();


// スライダー
document.addEventListener("DOMContentLoaded", function () {
    const mainSlider = document.querySelector("#main-gallery-carousel");
    const thumbnailSlider = document.querySelector("#thumbnail-gallery-carousel");

    if (mainSlider && thumbnailSlider) {
        const main = new Splide(mainSlider, {
            type: "fade",
            rewind: true,
            pagination: false,
            arrows: false,
        });

        const thumbnails = new Splide(thumbnailSlider, {
            fixedWidth: 160,
            perPage: 3,
            pagination: false,
            isNavigation: true,
            focus: "center",
            breakpoints: {
                400: {
                    fixedWidth: 80,
                },
            },
        });
        main.sync(thumbnails);
        main.mount();
        thumbnails.mount();
        //ビジュアルスライダー
    };

    const visualSplide = document.querySelector('.visualSlide');
    if (visualSplide) {
        const visualSlide = new Splide(visualSplide, {
            type: "fade",
            speed: 2000,
            rewind: true,
            autoplay: true,
            interval: 3000,
            pauseOnHover: false,
            pagination: true,
            arrows: false,
        });
        visualSlide.mount();
    }
});

// スライダー（ホワイトニング）
document.addEventListener("DOMContentLoaded", function () {
    const target = '.voiceSlide';

    // ターゲット要素を探す
    const element = document.querySelector(target);

    // ターゲット要素が見つかった場合にのみ Splide を初期化する
    if (element) {
        const options = {
            mediaQuery: 'min',
            perPage: 1,
            type: 'loop',
            gap: 24,
            breakpoints: {
                600: {
                    perPage: 1,
                },
                1025: {
                    perPage: 3,
                    gap: 32,
                }
            },
        };

        const whiteningSplide = new Splide(target, options);
        whiteningSplide.mount();
    }
});

// 店舗情報モーダル
document.addEventListener('DOMContentLoaded', function () {
    const shopServiceLinks = document.querySelectorAll('ul.shopServiceinfo_ul li a');

    shopServiceLinks.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault();
            const content = this.nextElementSibling.innerHTML;
            const overlay = document.createElement('div');
            overlay.classList.add('overlay_shopService');
            const popupContent = document.createElement('div');
            popupContent.classList.add('popup-content');
            popupContent.innerHTML = content;
            overlay.appendChild(popupContent);
            document.body.appendChild(overlay);
            overlay.style.display = 'block';
        });
    });

    // オーバーレイのクリックで閉じる
    document.addEventListener('click', function (event) {
        const overlay = document.querySelector('.overlay_shopService');
        if (overlay && event.target.classList.contains('overlay_shopService')) {
            overlay.style.display = 'none';
            overlay.remove();
        }
    });

    // 閉じるボタンのクリックで閉じる（イベントデリゲーションを使用）
    document.addEventListener('click', function (event) {
        if (event.target.classList.contains('btn_closeClick')) {
            const overlay = event.target.closest('.overlay_shopService');
            if (overlay) {
                overlay.style.display = 'none';
                overlay.remove();
            }
        }
    });
});




document.addEventListener('DOMContentLoaded', function () {
    const openbtn = document.querySelector('.openbtn');
    const shopNavigation = document.querySelector('#shopNavigation');

    if (openbtn && shopNavigation) {
        openbtn.addEventListener('click', function () {
            openbtn.classList.toggle('active');
            shopNavigation.classList.toggle('active');
        });
    }
    //.shop-aboutをactiveをremove
    const shopAbout = document.querySelector('.shop-about');
    if (shopAbout) {
        shopAbout.addEventListener('click', function () {
            shopNavigation.classList.remove('active');
            openbtn.classList.remove('active');
        });
    }
});


// 特設サイト（ペア）タブメニュー
document.addEventListener('DOMContentLoaded', function () {
    const bodyId = document.body.id;

    if (bodyId === 'pair') {
        const tabButtons = document.querySelectorAll('.tab__btn');
        const images = document.querySelectorAll('.price__inner__content .image');

        tabButtons.forEach(button => {
            button.addEventListener('click', function () {
                // すべての画像のactiveクラスを削除
                images.forEach(image => image.classList.remove('active'));

                // 対応する画像にactiveクラスを追加
                const targetClass = this.classList.contains('ladies') ? 'ladies' : 'mens';
                document.querySelector(`.image.${targetClass}`).classList.add('active');
            });
        });
    }
});

// 特設サイトのアコーディオンメニューとモーダルウィンドウ
document.addEventListener('DOMContentLoaded', function () {
    // body要素のidを取得
    const bodyId = document.body.id;
    // 対象とするidのリスト
    const validIds = ['mens', 'ladies', 'kids', 'pair'];

    // モーダルウィンドウ要素の参照を格納する変数
    let modal, modalMask;

    // モーダルウィンドウの開閉関数
    function openModal() {
        modal.classList.add('active');
        modalMask.classList.add('active');
    }

    function closeModal() {
        modal.classList.remove('active');
        modalMask.classList.remove('active');
    }

    // bodyのidが対象リストに含まれる場合のみ実行
    if (validIds.includes(bodyId)) {
        // アコーディオンメニューのセットアップ

        // モーダルウィンドウのセットアップ
        const openModalBtns = document.querySelectorAll('.modal__open');
        modal = document.querySelector('.modal');
        const closeModalBtn = document.querySelector('.modal__close');
        modalMask = document.querySelector('.modal__mask');

        if (modal && closeModalBtn && modalMask) {
            openModalBtns.forEach(openModalBtn => {
                openModalBtn.addEventListener('click', function (event) {
                    event.preventDefault();
                    openModal();
                });
            });

            closeModalBtn.addEventListener('click', closeModal);
            modalMask.addEventListener('click', closeModal);
        }
    }
});
document.addEventListener("DOMContentLoaded", function () {
    if (document.querySelectorAll(".question").length > 0) {
        setupAccordion();
    }
});

function setupAccordion() {
    const questions = Array.from(document.querySelectorAll(".question"));

    for (let i = 0; i < questions.length; i++) {
        let question = questions[i];
        let answer = question.nextElementSibling;

        question.addEventListener("click", () => {
            question.classList.toggle("open");
            if (answer.style.height) {
                answer.style.height = null;
            } else {
                answer.style.height = answer.scrollHeight + 'px';
            }
        });
    }
}

document.addEventListener('DOMContentLoaded', function () {
    const videoPC = document.getElementById('video-pc');
    const videoSP = document.getElementById('video-sp');

    function toggleVideo() {
        if (window.innerWidth >= 768) {
            videoPC.play();
            videoSP.pause();
        } else {
            videoSP.play();
            videoPC.pause();
        }
    }

    window.addEventListener('resize', toggleVideo);
    toggleVideo(); // 初期状態を設定
});